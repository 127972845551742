import React from "react";
import "./ContactUs.scss";
import { Grid } from "@mui/material";
import Typography from "../../components/kit/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  backgroundColor1,
  primaryColor1,
  redColor2,
} from "../../utils/VARIABLES";

export default function ContactUs() {
  
  const whatsappMessage = "¡Hola! Me gustaría obtener más información sobre gestión digital.";
  const gmailMessage = "¡Hola! Me gustaría obtener más información sobre gestión digital.";

  return (
    <Grid container className="contact-container">
      <Grid className="title-container" container>
        <Typography color={backgroundColor1} variant="h1" className="title">
          <>
          ¡Potencia tu negocio con Gestión Digital!  <br />
          Control completo de tu negocio en un click
          </>
        </Typography>
      </Grid>

      <Grid container className="cards-container">
        <Grid className="card-contact">
          <Grid className="card-header">
            <img
              className="profile-img"
              src="/images/marcos.jpeg"
              alt="Profile 1"
            />
          </Grid>
          <Grid className="card-body">
            <Typography type="bold" variant="paragraph-24">
              Marcos Gradac
            </Typography>
            <p className="role">CEO - Gestión Digital</p>
            <p className="contact-info">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                href={`mailto:marcosgradac2023@gmail.com?subject=Consulta sobre gestión digital&body=${encodeURIComponent(gmailMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                marcosgradac2023@gmail.com
              </a>
            </p>
            <p className="contact-info">
              <FontAwesomeIcon icon={faWhatsapp} />{" "}
              <a
                href={`https://wa.me/3571312773?text=${encodeURIComponent(whatsappMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                3571312773
              </a>
            </p>
          </Grid>
        </Grid>

        <Grid className="card-contact">
          <Grid className="card-header">
            <img
              className="profile-img"
              src="/images/lucas.jpg"
              alt="Profile 1"
            />
          </Grid>
          <Grid className="card-body">
            <Typography type="bold" variant="paragraph-24">
              Lucas Caballero
            </Typography>
            <p className="role">CTO - Gestión Digital</p>
            <p className="contact-info">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                href={`mailto:lucaserfurt321@gmail.com?subject=Consulta sobre gestión digital&body=${encodeURIComponent(gmailMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                lucaserfurt321@gmail.com
              </a>
            </p>
            <p className="contact-info">
              <FontAwesomeIcon icon={faWhatsapp} />{" "}
              <a
                href={`https://wa.me/3517642626?text=${encodeURIComponent(whatsappMessage)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                3517642626
              </a>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
