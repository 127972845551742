import { numericFormatter as reactNumericFormatter } from "react-number-format";
import Moment from "moment";

// function to format numbers as currency
// example: 1234.56 -> $1,234.56
export function numericFormatter(numStr: string | number | null | undefined): string {
  if(numStr === null || numStr === undefined || numStr === "-") return "-";
  if(typeof numStr === "number") numStr = numStr.toString();
  return reactNumericFormatter(numStr, {
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: true,
    prefix: "$",
  });
}

// function to format dates
// example: 2020-01-01 -> 01/01/2020
export function dateFormatter(
  dateStr: string,
  withHours: boolean = false
): string {
  if (!dateStr) return "-";
  return Moment(dateStr).format("DD-MM-YYYY" + (withHours ? " HH:mm" : ""));
}

// function to get the difference between two dates in hours and minutes
export function getDifferenceInHHMM(date1: Date, date2: Date): string {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  const totalMinutes = Math.ceil(diffInMs / (1000 * 60)); // Convertir a minutos
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  
  // Formatear con dos dígitos
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

// function to transform month number to month name
export function monthFormatter(month: number): string {
  switch (month) {
    case 1:
      return "Enero";
    case 2:
      return "Febrero";
    case 3:
      return "Marzo";
    case 4:
      return "Abril";
    case 5:
      return "Mayo";
    case 6:
      return "Junio";
    case 7:
      return "Julio";
    case 8:
      return "Agosto";
    case 9:
      return "Septiembre";
    case 10:
      return "Octubre";
    case 11:
      return "Noviembre";
    default:
      return "Diciembre";
  }
}
