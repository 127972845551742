import { Grid } from "@mui/material";
import TextInput from "../../kit/Inputs/Text";
import "./UpdateDrawerProduct.scss";
import { useEffect, useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { postNewProduct, Product, ProductDTO } from "../../../services/product";
import NumberInput from "../../kit/Inputs/Number";
import { getAllTag, Tag } from "../../../services/tags";
import SelectInput from "../../kit/Inputs/Select";
import Typography from "../../kit/Typography";
import DateInput from "../../kit/Inputs/Date";

interface Props {
  product: Product;
  closeDrawer: () => void;
  openDrawer: boolean;
  updatePage: () => void;
}

export default function UpdateProductDrawer(props: Props) {
  const [productDTO, setProductDTO] = useState<ProductDTO>({
    ...props.product,
    tagId: props?.product?.tag?.id,
    supplierId: props?.product?.supplier?.id,
  });

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [tags, setTags] = useState<Tag[]>([]);

  const handleUpdateProduct = () => {
    setDrawerStatus('loading');
    (async () => {
      let response = await postNewProduct(productDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleGetTags = () => {
    (async () => {
      let response = await getAllTag();
      if (response.status === 200) {
        setTags(response.data?.tags || []);
      }
    })();
  };
  useEffect(() => {
    handleGetTags();
  }, []);

  const disableSupplierDTO = () => {
    if (
      productDTO.name === "" ||
      !productDTO.amount ||
      drawerStatus === "loading"
    ) return true;
    return false;
  };

  return (
    <Grid container>
      <SideDrawer
        title="Editar producto"
        disabled={disableSupplierDTO()}
        saveOnclick={() => {
          handleUpdateProduct();
        }}
        saveLabel="Guardar"
        closeDrawer={props.closeDrawer}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid className="update-product-drawer" container>
          <Grid className="update-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.type}
              onChange={(type) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  type,
                }))
              }
              label="Tipo"
              items={[{ value: 'unit', label: 'Unidad' }, { value: 'weight', label: 'Peso' }]}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <TextInput
              value={productDTO.name}
              onChange={(name) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  name,
                }))
              }
              label={<span className="required-label">Nombre</span>}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <TextInput
              value={productDTO.code}
              onChange={(code) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  code,
                }))
              }
              label="Código"
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              value={productDTO.purchasePrice || ""}
              onChange={(purchasePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  purchasePrice,
                }))
              }
              label="Precio compra"
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              value={productDTO.amount || ""}
              onChange={(amount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  amount,
                }))
              }
              label={<span className="required-label">Precio de venta</span>}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.tagId}
              onChange={(tagId: number) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  tagId,
                }))
              }
              label="Etiqueta"
              items={tags.map((tag: Tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Grid>
          <Grid className="edit-product-drawer-item" container>
            <Typography variant="paragraph-18" type="semibold">Venta por mayor</Typography>
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              value={productDTO.wholesaleMinQuantity || ""}
              onChange={(wholesaleMinQuantity) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleMinQuantity,
                }))
              }
              label={`Cantidad mínima ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              isRequired={!!productDTO.wholesaleMinQuantity}
              allowDecimals
              thousandSeparator
              value={productDTO.wholesaleAmount || ""}
              onChange={(wholesaleAmount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleAmount,
                }))
              }
              label={`Precio unitario por mayor ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <Grid container>
              <Typography variant="paragraph-18" type="semibold">Cambio de precio</Typography>
            </Grid>
            <Grid container>
              <Typography variant="paragraph-14">Se actualizará automáticamente el precio en el rango horario definido</Typography>
            </Grid>
          </Grid>
          <Grid className="update-product-drawer-item" container spacing={2}>
            <Grid item xs={6}>
              <DateInput
                label="¿Desde qué horario?"
                date={productDTO.priceStartTime || ""}
                onChange={(priceStartTime: string) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    priceStartTime,
                  }))
                }
                variant="calendarOnlyHours"
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                label="¿Hasta qué horario?"
                date={productDTO.priceEndTime || ""}
                minDate={productDTO.priceStartTime || undefined}
                disabled={!productDTO.priceStartTime}
                onChange={(priceEndTime: string) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    priceEndTime,
                  }))
                }
                variant="calendarOnlyHours"
              />
            </Grid>
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              allowDecimals
              thousandSeparator
              value={productDTO.productRangePrice || ""}
              onChange={(productRangePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  productRangePrice,
                }))
              }
              label="Monto a aplicar en el rango horario"
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
