import { API_URL } from "../../config/env";
import axios from "axios";
import { getToken } from "../utils/token";
import React from "react";

export type RoleType = "super_admin" | "admin" | "user" | "employee";

export type User = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  garageName: string;
  garageAddress: string;
  email: string;
  phone: string;
  acceptTYC: boolean;
  roles: {
    id: number;
    role: RoleType;
  }[];
  organization: {
    id: number;
    name: string;
    organizationConf: OrganizationConf;
  };
};

export type OrganizationConf = {
  id: number;
  creditPercentage: number;
  debitPercentage: number;
  transferPercentage: number;
  voucherPrice: number;
};

export type NotificationMessageContent = {
  whatsAppRentDueContent: string;
};

export async function updateNotificationMessageContentDTO(DTO: NotificationMessageContent) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(
      API_URL + "/organization/conf/notification-message",
      DTO,
      {
        headers,
      }
    );
    if (response.status === 200) return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export async function getMe() {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + `/user/me`, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export type GeneralUpdateVehiclePriceDTO = {
  amount: number;
  updateRents: boolean;
};
export async function updateGeneralVehicleAmount(DTO: GeneralUpdateVehiclePriceDTO): Promise<OrganizationConf | null> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/organization/conf/vehicle-price", DTO, { headers });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function acceptTyC() {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      API_URL + "/user/accept-tyc",
      {},
      {
        headers,
      }
    );
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export const UserContext = React.createContext<{
  user: User | null;
  setUser: (_user: User) => void;
  highestRole: RoleType | null;
}>({
  user: null,
  setUser: () => { },
  highestRole: null,
});

export const permissions = {
  canShowAccountStatusPage: (user: User | null) => {
    return (
      user &&
      user.roles.some(
        (role) => role.role === "super_admin" || role.role === "admin"
      )
    );
  },
  canShowSuppliers: (user: User | null) => {
    return (
      user &&
      user.roles.some(
        (role) => role.role === "super_admin" || role.role === "admin"
      )
    );
  }
};

export type UpdateUserDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  garageName: string;
  garageAddress: string;
};

export async function patchUserDTO(DTO: UpdateUserDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/user", DTO, {
      headers,
    });
    if (response.status === 200) return response.data;
  } catch (e) {
    console.warn(e);
  }
}
