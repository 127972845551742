import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Typography from "../../components/kit/Typography";
import "./Session.scss";
import TextInput from "../../components/kit/Inputs/Text";
import InputPassword from "../../components/kit/Inputs/Password";
import { logIn } from "../../services/session";
import ButtonLevel from "../../components/kit/Buttons";
import Spinner from "../../components/kit/Spinner";
import Alert, { AlertType } from "../../components/kit/Alert";
import ModalTermsAndConditions from "../../components/ModalTermsAndConditions";
import { primaryColor2 } from "../../utils/VARIABLES";
interface Props {
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}
type SpinnerStatus = "close" | "success" | "error" | "prosessing";

export default function SessionPage(props: Props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [alertStatus, setAlertStatus] = useState<AlertType>("close");
  const [user, setUser] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [spinnerStatus, setSpinnerStatus] =
    React.useState<SpinnerStatus>("close");

  const handleLogin = () => {
    setSpinnerStatus("prosessing");
    (async () => {
      const token = await logIn(user, password);
      if (!token) {
        setSpinnerStatus("error");
        setAlertStatus("error");

        return;
      }
      localStorage.setItem("access_token", token.access_token);
      // 8 hours to expirate token
      const expiration = new Date();
      expiration.setHours(expiration.getHours() + 8);
      localStorage.setItem("token_expiration", expiration.toString());

      props.setToken(token.access_token);

      setSpinnerStatus("success");
      setAlertStatus("success");
    })();
  };

  return (
    <Grid className="desktop-session" container>
      <Grid className="left-effect">
        <img
          src="svg/session/shadow-primary-color-bg.svg"
          alt="left-background"
        />
      </Grid>
      <Grid className="right-effect">
        <img
          src="svg/session/shadow-primary-color-2-bg.svg"
          alt="right-background"
        />
      </Grid>
      <Grid className="session-content" container>
        <Grid className="session-content-box">
          <Grid className="session-title" container>
            <Typography variant="h1" color="whiteColor" type="bold">
              ¡Hola!
            </Typography>
          </Grid>
          <Grid className="session-subtitle" container>
            <Typography type="semibold" variant="h2">
              <>
                ¡Bienvenido a nuestro sistema de <br /> gestión para tu negocio!
              </>
            </Typography>
          </Grid>
          <Grid className="login-inputs" container>
            <Grid container className="login-menu-user">
              <TextInput
                helperText={
                  alertStatus === "error"
                    ? {
                        text: "",
                        type: "error",
                      }
                    : undefined
                }
                placeholder="Escribe tu usuario.."
                onChange={(user: string) => setUser(user)}
                label="¿Cuál es tu usuario?"
              />
            </Grid>
            <Grid className="login-menu-password" container>
              <InputPassword
                helperText={
                  alertStatus === "error"
                    ? {
                        text: "",
                        type: "error",
                      }
                    : undefined
                }
                placeholder="Escribe tu contraseña.."
                onChange={(pass: string) => setPassword(pass)}
                label="¿Y tu contraseña?"
              />
            </Grid>
            <Grid container className="login-menu-button">
              {spinnerStatus !== "prosessing" && (
                <ButtonLevel
                  disabled={user === "" || password === ""}
                  color="primary"
                  size="large"
                  variant="contained"
                  title="Ingresar"
                  onClick={handleLogin}
                />
              )}
              <Grid container>
                {spinnerStatus === "prosessing" && <Spinner />}
              </Grid>
            </Grid>
          </Grid>
          <Divider className="divider" />
          <Grid className="button-terms-and-condition" container>
            <ButtonLevel
              color="none"
              align="left"
              onClick={() => setOpenDrawer(true)}
              title={
                <Typography type="semibold" color={primaryColor2}>
                  Términos y Condiciones.
                </Typography>
              }
              variant="text"
            />
          </Grid>
          <Grid className="create-account" container alignItems="center">
            <Grid className="create-account-description" item>
              <Typography
                type="medium"
                variant="paragraph-14"
                text="¿Todavía no tenés una cuenta?"
              />
            </Grid>
            <Grid item>
              <ButtonLevel
                href="/contactos"
                variant="text"
                color="secondary"
                title="Contáctanos"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openDrawer && (
        <ModalTermsAndConditions
          closeModal={() => setOpenDrawer(false)}
          open={openDrawer}
        />
      )}
      {alertStatus === "error" && (
        <Alert
          setAlertStatus={setAlertStatus}
          severity={alertStatus}
          message="Verifica tus datos e inténtalo de nuevo"
        />
      )}
    </Grid>
  );
}
