import { Grid, List } from "@mui/material";
import ButtonLevel from "../../components/kit/Buttons";
import "./Cart.scss";
import BasicTable, { BasicTableStatus } from "../../components/kit/BasicTable";
import { useContext, useEffect, useRef, useState } from "react";
import { addProductToCart, addPromotionToCart, CartPromotionProduct, changeProductQuantity, changePromotionQuantity, clearCart, getActiveCart, postVoucherDelivery, postVoucherRefound, removeProductFromCart, removePromotionFromCart, type Cart } from "../../services/cart";
import { getProductByCodeOrName } from "../../services/product";
import { numericFormatter } from "../../utils/formatters";
import NumberInput from "../../components/kit/Inputs/Number";
import Typography from "../../components/kit/Typography";
import { grayColor, greenColor2 } from "../../utils/VARIABLES";
import Alert, { AlertType } from "../../components/kit/Alert";
import CartPayDrawer from "../../components/Cart/CartPayDrawer";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BarcodeReader from "react-barcode-reader";
import { UserContext } from "../../services/user";
import ConfirmationModal from "../../components/kit/Modal/ConfirmationModal";

export default function CartDesktop() {
  const { user } = useContext(UserContext);

  const [tableStatus, setTableStatus] = useState<BasicTableStatus>("idle");
  const [cart, setCart] = useState<Cart | null>(null);
  const [search, setSearch] = useState<{
    value: string;
    loading: boolean;
    options: {
      id: number | string;
      label: string;
    }[];
  }>({
    value: "",
    options: [],
    loading: false
  });

  const [editQuantity, setEditQuantity] = useState<
    { id: number, quantity: number, type: 'promotion' | 'product' } | null
  >(null);
  const [alertStatusCollect, setAlertStatusCollect] =
    useState<AlertType>("close");
  const [collectCartDrawer, setCollectCartDrawer] = useState<Cart | null>(null);

  // VOUCHER
  const [openVoucherDelivery, setOpenVoucherDelivery] = useState(false);
  const [openVoucherRefound, setOpenVoucherRefound] = useState(false);
  const [alertVoucherStatus, setAlertVoucherStatus] =
    useState<AlertType>("close");

  const handleVoucherAction = () => {
    (async () => {
      let response;
      if (openVoucherDelivery) response = await postVoucherDelivery();
      if (openVoucherRefound) response = await postVoucherRefound();

      if (response && response.status === 201) {
        setAlertVoucherStatus('success');
        handleGerActiveCart();
      } else {
        setAlertVoucherStatus('error');
      }
    })();

    setOpenVoucherDelivery(false);
    setOpenVoucherRefound(false);
  }


  const handleGerActiveCart = () => {
    (async () => {
      const response = await getActiveCart();
      if (response.status === 200) {
        setCart(response.data);
        setTableStatus("success");
      } else {
        setTableStatus("error");
      }
    })();
  };
  useEffect(() => {
    handleGerActiveCart();
  }, []);

  const handleClearCart = () => {
    (async () => {
      const response = await clearCart();
      if (response.status === 200) {
        handleGerActiveCart();
        setSearch({
          ...search,
          value: ""
        });
      }
    })();
  }

  const handleSearchProductOptions = () => {
    if (search.value === "") {
      setSearch({
        ...search,
        options: [],
        loading: false
      });
      return;
    }
    setSearch({
      ...search,
      loading: true
    });
    (async () => {
      const response = await getProductByCodeOrName(search.value, true);
      if (response.status === 200) {

        // validate if the search is with barcode
        if (/^[0-9]+$/.test(search.value) && response.data && response.data.products.length === 1 && response.data.foundedPromotions.length === 0) {
          handleAddProductToCart(response.data.products[0].id, 1);

          setSearch({
            ...search,
            options: [],
            loading: true,
            value: ""
          });
        } else {
          const mappedProducts = response.data?.products?.map(product => ({ label: product.name, id: `product-${product.id}` })) || [];
          const mappedPromotions = response.data?.foundedPromotions?.map(promotion => ({ label: promotion.name, id: `promotion-${promotion.id}` })) || [];

          const options: { label: string; id: number | string }[] = mappedProducts.concat(mappedPromotions);

          setSearch({
            ...search,
            options,
            loading: false
          });
        }
      }
    })();
  }

  const handleAddProductToCart = (productId: number, quantity: number) => {
    (async () => {
      const response = await addProductToCart(productId, quantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();

    // find input inside of text-field-search-desktop div and clear the input and clear focus
    const input = document.querySelector('.text-field-search-desktop input'); // div

    if (input) {
      input.setAttribute('value', '');
      (input as HTMLInputElement).blur();
    }
  }

  // Barcode reading
  const handleBarcodeError = console.log;

  const handleBarcodeScan = (scannedText: string) => {
    (async () => {
      const response = await getProductByCodeOrName(scannedText, true);
      if (response.status === 200) {

        // validate if the search is with barcode
        if (/^[0-9]+$/.test(scannedText) && response.data && response.data.products.length === 1 && response.data.foundedPromotions.length === 0) {
          handleAddProductToCart(response.data.products[0].id, 1);
        }
      }
    })();
  };
  // End Barcode reading

  const handleAddPromotionToCart = (productId: number, quantity: number) => {
    (async () => {
      const response = await addPromotionToCart(productId, quantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();
  }

  const handleChangeQuantity = () => {
    if (!editQuantity || !editQuantity.quantity) return;

    if (editQuantity.type === 'product') {
      const canBeUpdate = (cart?.cartProducts.find(product => product.id === editQuantity.id)?.product.stock || 0) > editQuantity.quantity;
      if (!canBeUpdate) return;

      const cartProductToUpdate = editQuantity.id;
      (async () => {
        const response = await changeProductQuantity(editQuantity.id, editQuantity.quantity);
        if (response.status === 201) {
          if (cartProductToUpdate === editQuantity.id)
            setEditQuantity(null);
          handleGerActiveCart();
        }
      })();
    } else {
      const cartPromotionToUpdate = editQuantity.id;
      (async () => {
        const response = await changePromotionQuantity(editQuantity.id, editQuantity.quantity);
        if (response.status === 201) {
          if (cartPromotionToUpdate === editQuantity.id)
            setEditQuantity(null);
          handleGerActiveCart();
        }
      })();
    }


  }

  const handleRemoveProductFromCart = (cartProductId: number) => {
    if (!cart) return;
    const findProductQuantity = cart.cartProducts.find(({ id }) => id === cartProductId)?.quantity || 0;

    (async () => {
      const response = await removeProductFromCart(cartProductId, findProductQuantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();
  }

  const handleRemovePromotionFromCart = (cartPromotionId: number) => {
    if (!cart) return;
    const findCartPromotionQuantity = cart.cartPromotion.find(({ id }) => id === cartPromotionId)?.quantity || 0;

    (async () => {
      const response = await removePromotionFromCart(cartPromotionId, findCartPromotionQuantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | number | null>(null);
  useEffect(() => {

    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleChangeQuantity, 1000);
  }, [editQuantity]);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleSearchProductOptions, 500);
  }, [search.value]);

  const disablePayButton = !cart ||
    (cart.cartProducts.length === 0 && cart.cartPromotion.length === 0) ||
    (editQuantity !== null && editQuantity.type === 'product' && !(editQuantity.quantity < (cart.cartProducts.find(({ id }) => id === editQuantity.id)?.product.stock || 0)))

  const cartView: () => {
    id: number;
    name: string;
    type: 'weight' | 'unit' | 'promotion';
    quantity: number;
    stock: number | null;
    amount: number;
    products: CartPromotionProduct[];
  }[] = () => {
    const view: {
      id: number;
      name: string;
      type: 'weight' | 'unit' | 'promotion';
      quantity: number;
      stock: number | null;
      amount: number;
      products: CartPromotionProduct[];
    }[] = [];

    cart?.cartPromotion.forEach(cp => view.push({
      id: cp.id,
      name: cp.name,
      type: 'promotion',
      quantity: cp.quantity,
      stock: cp.products.reduce((final: CartPromotionProduct | null, product) => (!final || product.stock < final.stock ? product : final), null)?.stock || null,
      amount: cp.promotionAmount,
      products: cp.products
    }));

    cart?.cartProducts.forEach(cp => view.push({
      id: cp.id,
      name: cp.product.name,
      type: cp.product.type,
      quantity: cp.quantity,
      stock: cp.product.stock,
      amount: cp.product.wholesaleMinQuantity && cp.product.wholesaleMinQuantity <= cp.quantity ? cp.product.wholesaleAmount : cp.product.amount,
      products: []
    }));

    return view;
  };

  return (
    <Grid container className="cart-page">
      <Grid container className="cart-content">
        <Grid container className="cart-content-header" alignItems="center">
          <Grid item xs={3} className="cart-content-header-total-to-collect">
            <Typography className="cart-content-header-total-to-collect-title" variant="paragraph-16" type="bold">
              Total a cobrar
            </Typography>
            <Typography className="cart-content-header-total-to-collect-amount" variant="paragraph-24" color={greenColor2} type="semibold">
              {numericFormatter(cart?.totalAmount || 0)}
            </Typography>
          </Grid>
          <Grid item xs className="cart-content-header-buttons">
            <Grid item className="cart-content-button">
              <ButtonLevel
                title="Limpiar carrito"
                size="medium"
                onClick={handleClearCart}
                variant="outlined"
                disabled={!cart || (cart.cartProducts.length === 0 && cart.cartPromotion.length === 0)}
              />
            </Grid>
            <Grid item className="cart-content-button">
              <ButtonLevel
                title="Cobrar"
                size="medium"
                onClick={() => setCollectCartDrawer(cart)}
                variant="contained"
                disabled={disablePayButton}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="cart-content-products">
          <BasicTable
            headOptions={[
              {
                label: "Entrega de vale",
                onClick: () => setOpenVoucherDelivery(true),
                show: !!(user && user.organization.organizationConf.voucherPrice),
              },
              {
                label: "Devolución de vale",
                onClick: () => setOpenVoucherRefound(true),
                show: !!(user && user.organization.organizationConf.voucherPrice),
              }
            ]}
            noFoundMessage={{
              title: "Busca para agregar productos",
              description: "Usa el buscador y selecciona el producto que deseas agregar al carrito."
            }}
            columns={["Nombre", "Precio unitario/kg", "Unidades / Peso", "Contenido promo", "Precio total", ""]}
            rows={cartView().map(({ quantity, type, name, id, stock, amount, products }) => [
              name,
              numericFormatter(amount),
              (
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <NumberInput
                      key={id}
                      placeholder={type === "weight" ? quantity + " Gr." : (type === 'unit' ? quantity + " Un." : quantity + " Promo.")}
                      variant="standard"
                      value={editQuantity && editQuantity.id === id ? editQuantity.quantity : quantity}
                      onChange={(value) => {
                        setEditQuantity({
                          id: id,
                          quantity: value,
                          type: type === 'promotion' ? 'promotion' : 'product'
                        });
                      }}
                      label=""
                      suffix={type === "weight" ? "Gr." : "Un."}
                      helperText={{
                        text: "Stock: " + (stock ? (stock + (type === 'unit' ? ' un.' : ' gr.')) : "Sin Limite"),
                        type: stock && stock < ((editQuantity && editQuantity.id === id && editQuantity.quantity) || quantity) ? "error" : "success",
                        show: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="paragraph-14" color={grayColor}>
                      {!type ? 'Sin Limite' : (type === "weight" ? " Gr." : type === "unit" ? " Un." : (quantity > 1 ? "Promos." : "Promo."))}
                    </Typography>
                  </Grid>
                </Grid>
              ),
              (
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  {products.map(p => (
                    <Grid container alignItems="center">
                      <Grid item>
                        <FiberManualRecordIcon sx={{ width: "10px", marginTop: "5px", marginRight: "5px" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="paragraph-12">
                          {`${p.quantity}${(p.type === 'unit' ? 'un.' : 'gr.')} - ${p.name}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </List>
              ),
              numericFormatter(type === "weight" ? amount * quantity / 1000 : amount * quantity),
              (
                <ButtonLevel
                  align="center"
                  title="X"
                  size="small"
                  onClick={() => {
                    if (type === 'promotion')
                      handleRemovePromotionFromCart(id)
                    else
                      handleRemoveProductFromCart(id)
                  }}
                  variant="text"
                  color="error"
                />
              )
            ]) || []
            }
            status={tableStatus}
            head="Carrito"
            search={{
              onChange: (value) => setSearch({
                ...search,
                value
              }),
              selectOption: (id: number | string) => {
                if (id.toString().includes('product')) {
                  const productId = parseInt(id.toString().split('-')[1])
                  handleAddProductToCart(productId, 1);
                } else {
                  const promotionId = parseInt(id.toString().split('-')[1])
                  handleAddPromotionToCart(promotionId, 1);
                }
              },
              value: search.value,
              placeholder: "Buscar producto",
              options: search.options,
              loading: search.loading,
              noOptionText: "No se encontraron productos"
            }}
          />
        </Grid>
      </Grid>
      <BarcodeReader onError={handleBarcodeError} onScan={handleBarcodeScan} />
      {cart && collectCartDrawer && (
        <CartPayDrawer
          cart={cart}
          setOpenModal={() => setCollectCartDrawer(null)}
          updatePage={handleGerActiveCart}
          setAlertStatus={(value: AlertType) => setAlertStatusCollect(value)}
        />
      )}
      <ConfirmationModal
        confirmationOption={{
          onclick: handleVoucherAction,
          title: "Aceptar",
        }}
        title="Entregar vale"
        description={`Se hará entrega de un vale por ${numericFormatter(user?.organization.organizationConf.voucherPrice)}`}
        closeModal={() => setOpenVoucherDelivery(false)}
        open={openVoucherDelivery}
      />
      <ConfirmationModal
        confirmationOption={{
          onclick: handleVoucherAction,
          title: "Aceptar",
        }}
        title="Devolver vale"
        description={`Se hará una devolución de un vale por ${numericFormatter(user?.organization.organizationConf.voucherPrice)}`}
        closeModal={() => setOpenVoucherRefound(false)}
        open={openVoucherRefound}
      />
      {alertVoucherStatus !== "close" && (
        <Alert
          setAlertStatus={setAlertVoucherStatus}
          severity={alertVoucherStatus}
          message={
            alertVoucherStatus === "success"
              ? `El vale fue ${openVoucherDelivery ? 'entregado' : openVoucherRefound ? 'devuelto' : 'procesado'} con exito`
              : `Ocurrío un error al ${openVoucherDelivery ? 'entregar' : openVoucherRefound ? 'devolver' : 'procesar'} el vale`
          }
        />
      )}
    </Grid>
  )
}