import { Grid } from "@mui/material";
import ButtonLevel from "../../kit/Buttons";
import BasicTable from "../../kit/BasicTable";
import { useEffect, useRef, useState } from "react";
import {
  defaultPaginatedResponse,
  defaultPaginateOptions,
  PaginatedResponse,
} from "../../../utils/generalServiceResponses";
import { deleteProduct, Product } from "../../../services/product";
import ConfirmationModal from "../../kit/Modal/ConfirmationModal";
import { getSupplierById, Supplier } from "../../../services/supplier";
import { getAllProductBySupplierId } from "../../../services/product";
import { numericFormatter } from "../../../utils/formatters";
import Alert, { AlertType } from "../../kit/Alert";
import UpdateProductDrawer from "../../Product/UpdateProductDrawer";
import { UpdateAmountsDrawer } from "../UpdateAmountsDrawer";
import AssociateProductWithSupplierDrawer from "../AssociateProductWithSupplierDrawer";

interface Props {
  supplierId: number;
}
export default function SupplierDetail(props: Props) {
  const [deleteProductModalConfirmation, setDeleteProductModalConfirmation] = useState<boolean>(false);
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [productsBySupplierPaginated, setProductsBySupplierPaginated] = useState<
    PaginatedResponse<Product>
  >(defaultPaginatedResponse);
  const [supplier, setSupplier] = useState<Supplier | null>(null);

  const [productToUpdate, setProductToUpdate] = useState<Product | null>(
    null
  );

  const [productToDelete, setProductToDelete] = useState<Product | null>(
    null
  );

  const [alertStatusDelete, setAlertStatusDelete] =
    useState<AlertType>("close");

  const [openAssociateProductWithSupplierDrawer, setOpenAssociateProductWithSupplierDrawer] = useState(false);
  const [openUpdateProductDrawer, setOpenUpdateProductDrawer] = useState(false);
  const [updateAmountsDrawer, setUpdateAmountsDrawer] = useState(false);

  const handleGetProductsBySupplier = () => {
    setStatus("loading");
    (async () => {
      const response = await getAllProductBySupplierId(
        paginateOptions,
        props.supplierId
      );
      if (response.data !== null) {
        setProductsBySupplierPaginated(response);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };

  const handleDeleteProduct = () => {
    (async () => {
      if (!productToDelete) return;
      const response = await deleteProduct(productToDelete?.id);
      if (response.status === 200) {
        handleGetProductsBySupplier();
        setAlertStatusDelete("success");
      } else {
        setAlertStatusDelete("error");
      }

    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleGetProductsBySupplier, 300);
  }, [paginateOptions.search, paginateOptions.page, paginateOptions.limit]);

  const handleGetSupplier = () => {
    (async () => {
      const response = await getSupplierById(
        props.supplierId
      );
      if (response.status === 200) {
        setSupplier(response.data);
      }
    })();
  }
  useEffect(() => handleGetSupplier, []);


  return (
    <Grid container className="supplier-dashboard">
      <Grid className="supplier-dashboard-content" container>
        <BasicTable
          status={status}
          head={supplier?.name || ""}
          search={{
            value: paginateOptions.search,
            onChange: (value) => {
              if (value === "") setStatus("loading");
              setPaginateOptions({ ...paginateOptions, search: value });
            },
            placeholder: "Buscar por proveedor",
          }}
          rightElement={
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={3}>
                <ButtonLevel
                  disabled={productsBySupplierPaginated.totalItems === 0}
                  variant="outlined"
                  align="center"
                  title="Cambiá los precios"
                  onClick={() => setUpdateAmountsDrawer(true)}
                />
              </Grid>
              <Grid item xs={3}>
                <ButtonLevel
                  align="center"
                  title="Carga un producto"
                  onClick={() => setOpenAssociateProductWithSupplierDrawer(true)}
                />
              </Grid>
            </Grid>
          }
          options={[
            {
              label: "Editar ",
              onClick: (rowIndex: number) => {
                const supplierDetail = productsBySupplierPaginated.data[rowIndex];
                setProductToUpdate(supplierDetail);
                setOpenUpdateProductDrawer(true)
              },
            },
            {
              label: "Eliminar ",
              onClick: (rowIndex: number) => {
                const product = productsBySupplierPaginated.data[rowIndex];
                setProductToDelete(product);
                setDeleteProductModalConfirmation(true);
              },
            },
          ]}
          columns={[
            "Id",
            "Nombre",
            "Stock",
            "Precio de compra",
            "Precio de venta",
          ]}
          rows={productsBySupplierPaginated.data.map((product) => {
            return [
              product.id,
              product.name,
              product.stock,
              numericFormatter(product.purchasePrice),
              numericFormatter(product.amount)
            ];
          })}
          paginateOptions={{
            currentPage: paginateOptions.page,
            rowsPerPage: paginateOptions.limit,
            setCurrentPage: (page: number) => {
              setPaginateOptions({ ...paginateOptions, page });
            },
            totalItems: productsBySupplierPaginated.totalItems,
          }}
        />
        <AssociateProductWithSupplierDrawer
          updatePage={handleGetProductsBySupplier}
          openDrawer={openAssociateProductWithSupplierDrawer}
          setOpenDrawer={setOpenAssociateProductWithSupplierDrawer}
          supplierId={props.supplierId}
        />
        {productToUpdate !== null && (
          <UpdateProductDrawer
            product={productToUpdate}
            updatePage={handleGetProductsBySupplier}
            openDrawer={openUpdateProductDrawer}
            closeDrawer={() => {
              setProductToUpdate(null);
            }}
          />
        )}
        {updateAmountsDrawer && (
          <UpdateAmountsDrawer
            supplierId={props.supplierId}
            closeDrawer={() => {
              setUpdateAmountsDrawer(false)
              handleGetProductsBySupplier()
            }}
            openDrawer={updateAmountsDrawer}
          />
        )}
        <ConfirmationModal
          title="Eliminar Producto"
          description={
            <>
              Este producto se eliminara permanentemente. <br />
              ¿Está seguro que desea continuar?
            </>
          }
          closeModal={() => setDeleteProductModalConfirmation(false)}
          open={deleteProductModalConfirmation ? true : false}
          confirmationOption={{
            onclick: () => {
              handleDeleteProduct();
              setDeleteProductModalConfirmation(false);
              setProductToDelete(null);
            },
            title: "Eliminar",
            color: "error",
          }}
        />
        {alertStatusDelete !== "close" && (
          <Alert
            setAlertStatus={setAlertStatusDelete}
            severity={alertStatusDelete}
            message={
              alertStatusDelete === "success"
                ? "El producto se elimino con exito"
                : "Ocurrío un error al eliminar el producto"
            }
          />
        )}
      </Grid>
    </Grid>
  );
}
