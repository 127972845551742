import { Grid } from "@mui/material";
import { Checkout, checkoutShiftToEs } from "../../../services/checkout";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { useState } from "react";
import Typography from "../../kit/Typography";
import { greenColor1, greenColor2, greenColor3, primaryColor1, primaryColor2, redColor1, redColor2 } from "../../../utils/VARIABLES";
import { dateFormatter, getDifferenceInHHMM, numericFormatter } from "../../../utils/formatters";

import "./CheckoutDetailDrawer.scss";

interface Props {
  checkout: Checkout;
  openDrawer: boolean;
  closeDrawer: () => void;
}

export default function CheckoutDetailDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  return (
    <Grid>
      <SideDrawer
        drawerStatus={drawerStatus}
        title="Detalles del cierre de caja"
        closeDrawer={props.closeDrawer}
        open={props.openDrawer}
      >
        <Grid container className="checkout-detail-drawer">
          <Grid container className="checkout-detail-drawer-shift">
            <Typography variant="paragraph-24" color={primaryColor2} type="bold">
              <>
                Turno {checkoutShiftToEs(props.checkout.shift)} - {props.checkout.name}
              </>
            </Typography>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Ingreso / Salida
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {dateFormatter(props.checkout.openedAt, true)} - {dateFormatter(props.checkout.closedAt, true)} ({getDifferenceInHHMM(new Date(props.checkout.openedAt), new Date(props.checkout.closedAt))}Hs.)
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total inicial
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.initialAmount)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total final registrado por el sistema
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.finalAmount)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total final registrado por el usuario
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.userFinalAmount)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Diferencia
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14" color={props.checkout.finalAmount > props.checkout.userFinalAmount ? redColor1 : greenColor2}>
                <>
                  {numericFormatter(Math.abs(props.checkout.finalAmount - props.checkout.userFinalAmount))}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-shift">
            <Typography variant="paragraph-24" color={primaryColor2} type="bold">
              <>
                Saldos finales por medio de pago
              </>
            </Typography>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total efectivo
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.totalInCash)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total transferencias
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.totalInTransfer)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total tarjeta debito
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.totalInDebit)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium">
                <>
                  Total tarjeta crédito
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14">
                <>
                  {numericFormatter(props.checkout.totalInCredit)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium" color={greenColor2}>
                <>
                  Total recaudado
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14" color={greenColor2}>
                <>
                  {numericFormatter(props.checkout.total)}
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="checkout-detail-drawer-item">
            <Grid container className="checkout-detail-drawer-item-title">
              <Typography variant="paragraph-18" type="medium" color={redColor1}>
                <>
                  Total extracciones
                </>
              </Typography>
            </Grid>
            <Grid container className="checkout-detail-drawer-item-content">
              <Typography variant="paragraph-14" color={redColor2}>
                <>
                  {numericFormatter(props.checkout.totalOutcome)}
                </>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}