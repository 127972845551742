import { Grid } from "@mui/material";
import MenuTop from "../../components/MenuTop";
import { Carousel } from "../../components/kit/Carousel";

import "./Checkout.scss";
import { dateFormatter, numericFormatter } from "../../utils/formatters";
import icons from "../../utils/icons";
import Typography from "../../components/kit/Typography";
import {
  darkColor1,
  darkColor2,
  grayColor,
  greenColor2,
  primaryColor1,
  redColor2,
} from "../../utils/VARIABLES";
import BasicTable from "../../components/kit/BasicTable";

import { useEffect, useState } from "react";
import {
  Checkout,
  checkoutShiftToEs,
  getCheckoutData,
  getCheckoutDataHistory,
} from "../../services/checkout";
import {
  PaginatedResponse,
  defaultPaginatedResponse,
} from "../../utils/generalServiceResponses";
import CheckoutDetailDrawer from "../../components/Checkout/CheckoutDetailDrawer";

export default function CheckoutPage() {
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );

  const [checkoutData, setCheckoutData] = useState<Checkout | null>(null);
  const [checkoutHistoryData, setCheckoutHistoryData] = useState<
    PaginatedResponse<Checkout>
  >(defaultPaginatedResponse);

  const [openCheckoutDetail, setOpenCheckoutDetail] = useState<Checkout | null>(null);

  const handleGetCheckoutHistoryData = () => {
    setStatus("loading");
    (async () => {
      const data = await getCheckoutDataHistory();
      if (data !== null) {
        setCheckoutHistoryData(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };
  const handleGetCheckoutData = () => {
    setStatus("loading");
    (async () => {
      const data = await getCheckoutData();
      if (data !== null) {
        setCheckoutData(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };
  useEffect(() => {
    handleGetCheckoutData();
    handleGetCheckoutHistoryData();
  }, []);

  return (
    <Grid container className="desktop-checkout">
      <Grid container className="desktop-checkout-content">
        <Typography variant="h2" color={primaryColor1} className="current-checkout">
          Caja en curso
        </Typography>
        <Carousel
          maxColumns={4}
          cardContent={[
            {
              title: "Total",
              amount: checkoutData?.total || 0,
              icon: icons.simpleCash,
            },
            {
              title: "Total en efectivo",
              amount: checkoutData?.totalInCash || 0,
              icon: icons.simpleCash,
            },
            {
              title: "Total con tarjetas",
              amount: checkoutData?.totalInDebit || 0,
              icon: icons.simpleCash,
            },
            {
              title: "Total por transferencias",
              amount: checkoutData?.totalInTransfer || 0,
              icon: icons.bank,
            },
            {
              title: "Extracción",
              amount: checkoutData?.totalOutcome || 0,
              icon: icons.cashRemove,
            },
          ].map((i) => cardContentMap(i))}
        />
        <BasicTable
          noFoundMessage={{
            title: "Caja de empleados",
            description: "En esta sección vas a poder ver el balance que tuvo cada empleado en su correspondiente turno, además de ver el balance de la caja en curso."
          }}
          status={status}
          head="Caja"
          columns={[
            "Fecha de apertura",
            "Turno",
            "Total inicio de caja",
            "Total cierre de caja (usuario)",
            "Total cierre de caja (sistema)",
            "Diferencia", // Diferencia entre el total de cierre de caja (usuario) y el total de cierre de caja (sistema)
            "Fecha hora de cierre",
          ]}
          rows={checkoutHistoryData.data.map((cd) => {
            return [
              dateFormatter(cd?.openedAt || "", true),
              `${checkoutShiftToEs(cd?.shift)} ${(cd.name ? " - " + cd.name : "")}`,
              numericFormatter(cd?.initialAmount || 0),
              numericFormatter(cd?.userFinalAmount || 0),
              <Typography
                color={
                  cd?.finalAmount > 0
                    ? greenColor2
                    : cd?.finalAmount < 0
                      ? redColor2
                      : darkColor2
                }
              >
                {numericFormatter(cd?.finalAmount || "0")}
              </Typography>,
              <Typography
                color={
                  cd?.finalAmount > cd?.userFinalAmount
                    ? redColor2
                    : cd?.finalAmount < cd?.userFinalAmount
                      ? greenColor2
                      : darkColor2
                }
              >
                {numericFormatter(Math.abs(cd?.finalAmount - cd?.userFinalAmount || 0))}
              </Typography>,

              dateFormatter(cd?.closedAt || "", true),
            ];
          })}
          options={[
            {
              label: "Ver detalles",
              onClick: (rowIndex) => {
                const checkout = checkoutHistoryData.data[rowIndex];
                setOpenCheckoutDetail(checkout);
              }
            }
          ]}
        />
      </Grid>
      {openCheckoutDetail !== null && (
        <CheckoutDetailDrawer
          checkout={openCheckoutDetail}
          openDrawer={openCheckoutDetail !== null}
          closeDrawer={() => setOpenCheckoutDetail(null)}
        />
      )}
    </Grid>
  );
}

function cardContentMap(item: { title: string; amount: number; icon: any }) {
  return (
    <Grid container className="card-content">
      <Grid container className="card-content-title" alignItems="center">
        <Grid item className="card-content-title-icon">
          {item.icon({ fill: item.amount === 0 ? grayColor : darkColor1 })}
        </Grid>
        <Grid item>
          <Typography variant="paragraph-16" type="semibold">
            {item.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Typography
          variant="paragraph-20"
          type="semibold"
          color={
            item.amount === 0
              ? grayColor
              : item.amount > 0
                ? greenColor2
                : redColor2
          }
        >
          {numericFormatter(Math.abs(item.amount))}
        </Typography>
      </Grid>
    </Grid>
  );
}
