import { Grid } from "@mui/material";
import TextInput from "../../kit/Inputs/Text";
import "./CreateDrawerProduct.scss";
import { useEffect, useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { getProductByCodeOrName, postNewProduct, ProductDTO } from "../../../services/product";
import NumberInput from "../../kit/Inputs/Number";
import { getAllTag, Tag } from "../../../services/tags";
import SelectInput from "../../kit/Inputs/Select";
import { getAllSuppliers, Supplier } from "../../../services/supplier";
import Typography from "../../kit/Typography";
import DateInput from "../../kit/Inputs/Date";

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  supplierId?: number;
  tagId?: number;
}

export default function CreateNewProductDrawer(props: Props) {
  const defaultProductDTO: ProductDTO = {
    name: null,
    amount: null,
    stock: null,
    code: null,
    type: "unit",
    purchasePrice: null, // precio de compra por unidad o kg
    dueDate: null,
    supplierId: !props.supplierId ? null : props.supplierId,
    tagId: !props.tagId ? null : props.tagId,
    wholesaleAmount: 0,
    wholesaleMinQuantity: null,
    priceStartTime: null,
    priceEndTime: null,
    productRangePrice: null
  }

  const [productDTO, setProductDTO] = useState<ProductDTO>(defaultProductDTO);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [tags, setTags] = useState<Tag[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const handlePostProduct = () => {
    setDrawerStatus("loading");
    (async () => {
      let response = await postNewProduct(productDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleGetTags = () => {
    if (props.tagId) return null;
    (async () => {
      let response = await getAllTag();
      if (response.status === 200) {
        setTags(response.data?.tags || []);
      }
    })();
  };

  const handleGetSuppliers = () => {
    if (props.supplierId) return null;

    (async () => {
      let response = await getAllSuppliers();
      if (response.data) {
        setSuppliers(response.data);
      }
    })();
  };

  const handleGetProductByCode = () => {
    (async () => {
      if (typeof productDTO.code !== "string") return;
      if (productDTO.code && productDTO.code.length > 5 && !/^\d+$/.test(productDTO.code)) return;
      const response = await getProductByCodeOrName(productDTO.code, true);

      if (response.status === 200) {
        const product = response.data?.products.find((product) => product.code === productDTO.code);
        if (product) {
          setProductDTO((prevState) => ({
            ...prevState,
            ...product,
            supplierId: product.supplier?.id ?? null,
            tagId: product.tag?.id ?? null,
          }));
        }
      }
    })();
  }
  useEffect(handleGetProductByCode, [productDTO.code]);

  useEffect(() => {
    handleGetTags();
    handleGetSuppliers();
  }, []);

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setDrawerStatus("idle");
    setProductDTO(defaultProductDTO);
  };

  const disable =
    drawerStatus === "loading" ||
    !productDTO.name ||
    !productDTO.amount ||
    !productDTO.stock ||
    !productDTO.tagId ||
    (!!productDTO.wholesaleMinQuantity && !productDTO.wholesaleAmount);

  return (
    <Grid container>
      <SideDrawer
        title="Crear nuevo producto"
        disabled={disable}
        saveOnclick={() => {
          handlePostProduct();
        }}
        saveLabel="Guardar"
        closeDrawer={() => {
          onCloseModal();
          props.updatePage();
        }}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid className="create-product-drawer" container>
          <Grid className="create-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.type}
              onChange={(type) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  type,
                }))
              }
              label="Tipo"
              items={[{ value: 'unit', label: 'Unidad' }, { value: 'weight', label: 'Peso' }]}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <TextInput
              isRequired
              value={productDTO.name || ""}
              onChange={(name) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  name,
                }))
              }
              label="Nombre"
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <TextInput
              value={productDTO.code || ""}
              onChange={(code) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  code,
                }))
              }
              label="Código"
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired
              allowDecimals
              thousandSeparator
              value={productDTO.amount || ""}
              onChange={(amount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  amount,
                }))
              }
              label={`Precio de venta ${productDTO.type === 'unit' ? 'por unidad' : 'por kg.'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired
              value={productDTO.stock || ""}
              onChange={(stock) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  stock,
                }))
              }
              label={`Stock ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              value={productDTO.purchasePrice || ""}
              onChange={(purchasePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  purchasePrice,
                }))
              }
              label={<span>Precio de compra </span>}
            />
          </Grid>
          {!props.tagId && (
            <Grid className="create-product-drawer-item" container>
              <SelectInput
                isRequired
                key={productDTO.tagId}
                itemSelected={productDTO.tagId}
                onChange={(tagId) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    tagId,
                  }))
                }
                label="Etiqueta"
                items={tags.map((tag: Tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              />
            </Grid>
          )}
          {!props.supplierId && (
            <Grid className="create-product-drawer-item" container>
              <SelectInput
                key={productDTO.supplierId}
                itemSelected={productDTO.supplierId}
                onChange={(supplierId) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    supplierId,
                  }))
                }
                label="Provedor"
                items={suppliers.map((tag: Supplier) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
              />
            </Grid>
          )}
          <Grid className="create-product-drawer-item" container>
            <Typography variant="paragraph-18" type="semibold">Venta por mayor</Typography>
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              value={productDTO.wholesaleMinQuantity || ""}
              onChange={(wholesaleMinQuantity) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleMinQuantity,
                }))
              }
              label={`Cantidad mínima ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              isRequired={!!productDTO.wholesaleMinQuantity}
              allowDecimals
              thousandSeparator
              value={productDTO.wholesaleAmount || ""}
              onChange={(wholesaleAmount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  wholesaleAmount,
                }))
              }
              label={`Precio unitario por mayor ${productDTO.type === 'unit' ? '(unidades)' : '(gramos)'}`}
            />
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <Grid container>
              <Typography variant="paragraph-18" type="semibold">Cambio de precio</Typography>
            </Grid>
            <Grid container>
              <Typography variant="paragraph-14">Se actualizará automáticamente el precio en el rango horario definido</Typography>
            </Grid>
          </Grid>
          <Grid className="create-product-drawer-item" container spacing={2}>
            <Grid item xs={6}>
              <DateInput
                label="¿Desde qué horario?"
                date={productDTO.priceStartTime || ""}
                onChange={(priceStartTime: string) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    priceStartTime,
                  }))
                }
                variant="calendarOnlyHours"
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                label="¿Hasta qué horario?"
                date={productDTO.priceEndTime || ""}
                minDate={productDTO.priceStartTime || undefined}
                disabled={!productDTO.priceStartTime}
                onChange={(priceEndTime: string) =>
                  setProductDTO((prevState) => ({
                    ...prevState,
                    priceEndTime,
                  }))
                }
                variant="calendarOnlyHours"
              />
            </Grid>
          </Grid>
          <Grid className="create-product-drawer-item" container>
            <NumberInput
              allowDecimals
              thousandSeparator
              value={productDTO.productRangePrice || ""}
              onChange={(productRangePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  productRangePrice,
                }))
              }
              label="Monto a aplicar en el rango horario"
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
