import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { Customer, getCustomerById } from "../../../services/customers";
import { BasicTableStatus } from "../../kit/BasicTable";

export default function CustomerTicket() {

  const [searchParams] = useSearchParams();
  const customerId = parseInt(searchParams.get("id") || "0");

  const [status, setStatus] = useState<BasicTableStatus>(
    "loading"
  );
  const [customer, setCustomer] = useState<Customer | null>(null);

  const handleGetCustomer = () => {
    if (!customerId) return;

    setStatus("loading");
    (async () => {
      const response = await getCustomerById(customerId);
      if (response.status === 200) {
        setCustomer(response.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  }
  useEffect(handleGetCustomer, [customerId]);



  return (
    <Grid container className="customer-ticket">
      {(!customerId || (status !== "loading" && !customer)) && (
        <Grid container className="customer-not-found">
          Cliente no encontrado
        </Grid>
      )}
      {customer && (
        <>
          <BrowserView>
            {browserPageView(customer)}
          </BrowserView>
          <MobileView>
            {mobilePageView(customer)}
          </MobileView >
        </>
      )}
    </Grid>
  )
}

function browserPageView(customer: Customer) {
  return (
    <Grid container className="customer-ticket-desktop">
      <Grid container>
        {customer.id} : {customer.firstName} {customer.lastName}
      </Grid>
    </Grid>
  )
}

function mobilePageView(customer: Customer) {
  return (
    <Grid container className="customer-ticket-desktop">
      Aca se ve lo que ve el que esta viendo todo lo que se ve en la pagina dentro de la vista celular
    </Grid>
  )
}