import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Grid } from "@mui/material";
import Typography from "../../Typography";
import "./SelectInput.scss";

interface Props {
  itemSelected?: string | number | null;
  onChange: (_value: string | number | any) => void;
  label: string | React.ReactElement;
  placeholder?: string;
  disabled?: boolean;
  isRequired?: boolean;
  items: {
    value: string | number;
    label?: string | React.ReactElement;
  }[];
  helperText?: {
    text: string;
    type: "sucess" | "warning" | "error";
  };
}

export default function SelectInput(props: Props) {
  const { placeholder = "", itemSelected = null } = props;

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value);
  };

  return (
    <Grid container className="select-input">
      <InputLabel shrink={false}>{!itemSelected && placeholder}</InputLabel>
      <Typography text={props.label as string} variant="paragraph-14" className={`${props.isRequired && "input-required"}`} />
      <Grid className="select-input-container" container>
        <FormControl color="secondary" sx={{ minWidth: "100%" }}>
          <Select
            size="small"
            className="select-input-material"
            value={itemSelected?.toString()}
            onChange={handleChange}
            disabled={props.disabled}
            MenuProps={{ PaperProps: { style: { maxHeight: '250px' } } }}
          >
            {props.items.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
          <Grid container>
            {props.helperText && (
              <Typography
                className={`helper-text ${props.helperText?.type}`}
                variant="paragraph-12"
              >
                {props.helperText?.text}
              </Typography>
            )}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
