import { Grid } from "@mui/material";
import "./UpdateProductStockDrawer.scss";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { patchUpdateProductStock, Product } from "../../../services/product";
import NumberInput from "../../kit/Inputs/Number";

interface Props {
  product: Product;
  closeDrawer: () => void;
  openDrawer: boolean;
  updatePage: () => void;
  supplierId: number | null;
}

export default function UpdateProductStockDrawer(props: Props) {
  const [newStock, setNewStock] = useState<number>(props.product.stock || 0);

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handleUpdateProduct = () => {
    (async () => {
      let response = await patchUpdateProductStock({ id: props.product.id, stock: newStock });
      if (response.status === 200) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  return (
    <Grid container>
      <SideDrawer
        title="Editar stock"
        disabled={!newStock}
        saveOnclick={() => {
          handleUpdateProduct();
          setDrawerStatus("success");
        }}
        saveLabel="Guardar"
        closeDrawer={props.closeDrawer}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid className="update-product-stock-drawer" container>
          <Grid className="update-product-stock-drawer-item" container>
            <NumberInput
              value={newStock}
              onChange={(newStock) =>
                setNewStock(newStock)
              }
              label="Nuevo stock"
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
