import { Grid, TextField } from "@mui/material";
import Typography from "../../Typography";
import "./NumberInput.scss";
import { NumericFormat as ReactNumericFormat } from "react-number-format";
import { darkColor3 } from "../../../../utils/VARIABLES";
import { useEffect, useRef } from "react";

interface Props {
  disabled?: boolean;
  value: string | number;
  label: string | React.ReactElement;
  onChange: (_value: number) => void;
  placeholder?: string;
  maxLengthMessage?: number;
  variant?: "outlined" | "filled" | "standard" | "line";
  allowDecimals?: boolean;
  thousandSeparator?: boolean;
  color?: string; // Ej.: #000000
  helperText?: {
    show?: boolean;
    text: string;
    type: "success" | "warning" | "error";
  };
  prefix?: string;
  suffix?: string;
  isRequired?: boolean;
}

export default function NumberInput(props: Props) {
  const { disabled = false, variant = "outlined", color = darkColor3, isRequired = false } = props;
  const isMaxLength = (newValue: string) => {
    return props.maxLengthMessage && newValue.length > props.maxLengthMessage;
  };

  return (
    <Grid container className="number-input">
      <Typography className={`number-input-label ${isRequired && "input-required"}`} text={props.label as string} variant="paragraph-14" />
      {variant === "line" && (
        <Grid className="number-input-line" container>
          <ReactNumericFormat
            style={{ color: color }}
            disabled={disabled}
            value={props.value}
            displayType="input"
            thousandSeparator={props.thousandSeparator}
            prefix={props.prefix}
            suffix={props.suffix}
            decimalScale={2}
            placeholder={props.placeholder}
            maxLength={props.maxLengthMessage ? props.maxLengthMessage + 3 : 100}
            onChange={(event) => {
              const regex = props.allowDecimals ? /[^0-9.]/g : /[^0-9]/g;
              const value = event.target.value.replace(regex, "");

              props.onChange(parseFloat(value))
            }}
          />
        </Grid>
      )}
      {variant !== "line" && (
        <TextField
          disabled={disabled}
          placeholder={props.placeholder}
          value={props.value}
          color="secondary"
          id="standard-number"
          onChange={(event) =>
            !isMaxLength(event.target.value.toString()) &&
            props.onChange(parseFloat(event.target.value))
          }
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant={variant}
          fullWidth
          inputProps={{
            onWheel: (event: any) => event.target.blur(), // Pierde el foco al hacer scroll lo que permite que no se cambie el valor
          }}
        />
      )}
      {props.helperText?.show && (
        <Grid container>
          <Typography
            className={`helper-text ${props.helperText?.type}`}
            variant="paragraph-12"
          >
            {props.helperText?.text}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}