import { useSearchParams } from "react-router-dom";
import { Grid, ButtonGroup, Button } from "@mui/material";
import CardTags from "../../components/Tag/TagCard";
import { TagTable } from "../../components/Tag/TagTable";
import { ProductTable } from "../../components/Product/ProductTable";

import "./Products.scss";

export default function Products() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tagId = searchParams.get("tid");
  const view = searchParams.get("vista") || "tabla"; // Por defecto, tabla

  const handleChangeView = (newView: string) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("vista", newView);
      params.delete("tid");
      return params;
    });
  };

  return (
    <Grid container className="products">
      <Grid item xs={12} sx={{ marginBottom: 2 }}>
        <ButtonGroup className="products__view" variant="contained">
          <Button
            variant={view === "tabla" ? "contained" : "outlined"}
            onClick={() => handleChangeView("tabla")}
          >
            Tabla
          </Button>
          <Button
            variant={view === "grilla" ? "contained" : "outlined"}
            onClick={() => handleChangeView("grilla")}
          >
            Grilla
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {tagId !== null ? (
          <TagTable tagId={parseInt(tagId)} />
        ) : view === "tabla" ? (
          <ProductTable />
        ) : (
          <CardTags />
        )}
      </Grid>
    </Grid>
  );
}