import axios from "axios";
import { API_URL } from "../../config/env";
import {
  DeleteResponse,
  GetResponse,
  PostResponse,
} from "../../utils/generalServiceResponses";
import { Movement } from "../movements";
import { Product, ProductType } from "../product";
import { getToken } from "../utils/token";

export enum CartStatus {
  PENDING = 'Pending',
  PAID = 'Paid',
  CANCELED = 'Canceled',
}
export type CartProduct = {
  id: number;
  quantity: number; // unit or weight in grams
  product: Product;
}
export type CartPromotionProduct = {
  id: number;
  name: string;
  quantity: number;
  type: ProductType;
  stock: number;
}
export type CartPromotion = {
  id: number;
  name: string;
  quantity: number;
  products: CartPromotionProduct[];
  promotionAmount: number;
}
export type Cart = {
  totalItems: number;
  totalAmount: number;
  date: Date;
  cartStatus: CartStatus;
  cartProducts: CartProduct[];
  cartPromotion: CartPromotion[];
  movements: Movement[];
};

export async function getActiveCart(): Promise<GetResponse<Cart>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(API_URL + "/cart", {
      headers
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function clearCart(): Promise<DeleteResponse> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.delete(API_URL + "/cart", {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function addProductToCart(productId: number, quantity: number): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/add-product", {
      productId,
      quantity
    }, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function addPromotionToCart(promotionId: number, quantity: number): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/add-promotion", {
      promotionId,
      quantity
    }, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function changeProductQuantity(cartProductId: number, quantity: number): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/set-product-quantity", {
      cartProductId,
      quantity
    }, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function changePromotionQuantity(cartPromotionId: number, quantity: number): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/set-promotion-quantity", {
      cartPromotionId,
      quantity
    }, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type PaymentMethod = "Cash" | "DebitCard" | "Transfer" | "CreditCard";
export type CollectCartDTO = {
  paymentMethod: PaymentMethod;
  changeReturned: boolean | null;
  amountPaid: number | null;
  collectedDate: string;
};

export async function collectCart(DTO: CollectCartDTO): Promise<PostResponse<any>> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/collect", DTO, {
      headers
    });

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function removeProductFromCart(cartProductId: number, quantity: number): Promise<DeleteResponse> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/remove-product", {
      cartProductId,
      quantity
    },
      { headers }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function removePromotionFromCart(cartPromotionId: number, quantity: number): Promise<DeleteResponse> {
  const token = await getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(API_URL + "/cart/remove-promotion", {
      cartPromotionId,
      quantity
    },
      { headers }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function postVoucherDelivery(): Promise<PostResponse<any>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/cart/voucher-delivery", {}, { headers });
    return response;
  } catch (e: any) {
    console.warn(e);
    return e.response;
  }
}

export async function postVoucherRefound(): Promise<PostResponse<any>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/cart/voucher-refound", {}, { headers });
    return response;
  } catch (e: any) {
    console.warn(e);
    return e.response;
  }
}