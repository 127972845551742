import { Fragment, useState } from "react";
import { primaryColor1 } from "../../../utils/VARIABLES";
import icons from "../../../utils/icons";
import ButtonLevel from "../Buttons";
import "./Search.scss";
import { Autocomplete, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";

interface SimpleSearchProps {
  onChange: (_value: string) => void;
  placeholder: string;
  value: string;
  onClick?: () => void;
}

export default function Search(props: SimpleSearchProps) {
  return (
    <Grid className="search" container>
      <Grid item xs={props.onClick ? 11 : 12}>
        <TextField
          fullWidth
          className="text-field-search-desktop"
          variant="outlined"
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          placeholder={props.placeholder}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              props.onChange("");
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search">
                  {icons.search({ width: 16, height: 16, fill: primaryColor1 })}
                </IconButton>
              </InputAdornment>
            ),
            "aria-label": "search",
          }}
        />
      </Grid>
      {props.onClick && (
        <Grid className="icon-button-filter" item xs={1}>
          <ButtonLevel onClick={props.onClick} icon={{ icon: icons.filter() }} />
        </Grid>
      )}
    </Grid>
  );
}

interface AutocompleteProps {
  onChange: (_value: string) => void;
  placeholder: string;
  value: string;
  onClick?: () => void;
  options: {
    id: number | string;
    label: string;
  }[];
  loading: boolean;
  selectOption: (id: number | string) => void;
  noOptionText?: string;
}
export function AutocompleteSearch(props: AutocompleteProps) {
  return (
    <Autocomplete
      className="autocomplete-search"
      options={props.options}
      loading={props.loading}
      disablePortal
      clearOnEscape
      noOptionsText={
        props.value === "" ?
          "Escribe para buscar" :
          (props.loading ? "Buscando..." : props.noOptionText || "No se encontraron resultados")
      }
      onChange={(_, newValue) => {
        if (newValue) {
          props.onChange("");
          props.selectOption(newValue.id);
        }
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          fullWidth
          className="text-field-search-desktop"
          variant="outlined"
          placeholder={props.placeholder}
          onChange={(event) => props.onChange(event.target.value)}
          value={props.value}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {props.loading ? <CircularProgress color="inherit" size={20} /> : icons.search({ width: 16, height: 16, fill: primaryColor1 })}
              </InputAdornment>
            ),
          }}
        />
      }
    />
  );
}
